<template>
   <div class="relative">
  <portal to="addlocation">
    <p slot-scope="{code}" >
 
  
   
          <a  class=" cursor-pointer " title="add-location" @click="showModal(code)" ><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="w-4 h-4 fill-current text-black-500 mx-1"
   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
  <g>
    <path d="M192,0C86.112,0,0,86.112,0,192c0,133.088,173.312,307.936,180.672,315.328C183.808,510.432,187.904,512,192,512
      s8.192-1.568,11.328-4.672c0,0,14.08-14.048,34.016-36.96c5.824-6.656,5.12-16.768-1.536-22.592
      c-6.624-5.76-16.704-5.088-22.592,1.536c-8.384,9.6-15.68,17.6-21.216,23.52C153.792,432,32,293.664,32,192
      c0-88.224,71.776-160,160-160s160,71.776,160,160c0,4.576-0.256,9.44-1.056,14.304c-1.472,8.704,4.384,16.96,13.12,18.432
      c8.704,1.376,16.96-4.416,18.432-13.12C383.616,204.96,384,198.304,384,192C384,86.112,297.888,0,192,0z"/>
  </g>
</g>
<g>
  <g>
    <path d="M192,96c-52.928,0-96,43.072-96,96s43.072,96,96,96c52.928,0,96-43.072,96-96C288,139.072,244.928,96,192,96z M192,256
      c-35.296,0-64-28.704-64-64s28.704-64,64-64s64,28.704,64,64S227.296,256,192,256z"/>
  </g>
</g>
<g>
  <g>
    <path d="M384,256c-70.592,0-128,57.408-128,128s57.408,128,128,128s128-57.408,128-128S454.592,256,384,256z M384,480
      c-52.928,0-96-43.072-96-96c0-52.928,43.072-96,96-96c52.928,0,96,43.072,96,96C480,436.928,436.928,480,384,480z"/>
  </g>
</g>
<g>
  <g>
    <path d="M432,368h-32v-32c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v32h-32c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16
      h32v32c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16v-32h32c8.832,0,16-7.168,16-16C448,375.168,440.832,368,432,368z"/>
  </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
           
          </a>
        </p>
    </portal>

    <portal  to="editlocation">
    <p slot-scope="{codeid}" >                  
             <a @click="locationproductEdit(codeid)" class="cursor-pointer" title="edit-location">
                 <svg id="Layer_1" enable-background="new 0 0 512.417 512.417"  viewBox="0 0 512.417 512.417" class="w-4 h-4 fill-current text-black-500 mx-1"  xmlns="http://www.w3.org/2000/svg"><path d="m506.324 407.537-156.147-155.563c-1.408-1.403-3.013-2.572-4.749-3.493 5.831-24.472 8.78-48.447 8.78-71.481 0-97.598-79.402-177-177-177s-177 79.402-177 177c0 39.711 8.726 81.884 25.936 125.348 13.549 34.218 32.379 69.356 55.967 104.437 39.964 59.438 79.521 97.973 81.186 99.584 3.877 3.754 8.895 5.631 13.912 5.631s10.034-1.877 13.912-5.631c2.208-2.138 51.016-49.75 94.838-120.755l121.425 120.971c3.903 3.888 9.009 5.832 14.116 5.832 5.119 0 10.237-1.953 14.142-5.858l70.71-70.711c3.755-3.755 5.863-8.85 5.858-14.161-.007-5.311-2.124-10.402-5.886-14.15zm-99.101-42.267-42.427 42.426-80.775-80.473-8.482-50.892 50.932 8.489zm-230.011 97.799c-37.353-40.787-137.004-161.309-137.004-286.069 0-75.542 61.458-137 137-137s137 61.458 137 137c0 20.429-2.727 41.859-8.1 63.874l-51.612-8.602c-6.37-1.06-12.862 1.019-17.43 5.586-4.567 4.567-6.647 11.059-5.586 17.43l14.142 84.853c.688 4.124 2.65 7.93 5.612 10.88l5.557 5.536c-28.8 48.364-61.487 86.772-79.579 106.512zm244.259 1.09-28.338-28.232 42.427-42.426 28.337 28.231zm-244.263-347.159c-33.084 0-60 26.916-60 60s26.916 60 60 60 60-26.916 60-60-26.916-60-60-60zm0 80c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z"/></svg>
                  </a>
   </p>
    </portal>
        
   <portal  to="addlocationform">
        <div v-if="this.show == 1" class="modal modal-mask">
      <div class="modal-wrapper px-4">
        <div class="modal-container w-full  max-w-md px-8 mx-auto">
          <div class="modal-header flex justify-between items-center">
            <h2>Add Location</h2>
            <button id="close-button" class="modal-default-button text-2xl py-1" @click="closeModal()">
              &times;
            </button>
          </div>
          <div class="modal-body" >
            <div class="flex items-center">
              <div class="w-full"> 
                <label for="obtained_marks" class="tw-form-label">Location</label>
             
              <div class="my-1 w-full">
                
                 <select name="location_id"  v-model="location_id" class="tw-form-control w-full">
                <option value="" disabled>Select location</option>
                <option v-for="location in locations" v-bind:value="location.id">{{location.name}}</option>
              </select><br>
                <span v-if="errors.location_id" class="text-red-500 text-xs font-semibold">{{errors.location_id[0]}}</span>
              
              </div>

              <div class="modal-body" >
            <div class="flex items-center">
              <div class="w-full"> 
               
              <div>
              <label class="tw-form-label">Start date</label>
              <div class="my-1">
                      <input v-model="start_date" name="start_date"  value="start_date" type="date" class="tw-form-control w-full"><span v-if="errors.start_date" class="text-red-500 text-xs font-semibold">{{errors.start_date[0]}}</span>
              </div>

              <div>
              <label class="tw-form-label">Remarks</label>
              <div class="my-1">
                      <textarea v-model="remarks" name="remarks"  value="remarks" type="textarea" placeholder="remarks" class="tw-form-control w-full"></textarea><span v-if="errors.remarks" class="text-red-500 text-xs font-semibold">{{errors.remarks[0]}}</span>
              </div>

              </div></div>
              </div></div></div></div></div>
            <div class="flex items-center">

              <div class="my-2 ">
                <input type="button" name=""  value="submit" @click="addlocationproduct()" class="btn btn-submit blue-bg text-white rounded px-3 py-1 mr-3 text-sm font-medium cursor-pointer">
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </portal>
    <!-- Edit topic model -->
    <portal  to="editlocationform">
       <div v-if="this.editshow == 1" class="modal modal-mask">
      <div class="modal-wrapper px-4">
        <div class="modal-container w-full  max-w-md px-8 mx-auto">
          <div class="modal-header flex justify-between items-center">
            <h2>Edit Location Product </h2>
            <button id="close-button" class="modal-default-button text-2xl py-1" @click="close()">
              &times;
            </button>
          </div>
          <div class="modal-body" >
            <div class="">
          
              <div class="w-full "> 
                <label for="obtained_marks" class="tw-form-label">Location</label>
             
              <div class="my-1 w-full">
                 <select name="editlocation_id"  v-model="editlocation_id" class="tw-form-control w-full">
                <option value="" disabled>Select location</option>
                <option v-for="location in locations" v-bind:value="location.id">{{location.name}}</option>
              </select><br>
                <span v-if="errors.location_id" class="text-red-500 text-xs font-semibold">{{errors.location_id[0]}}</span>
               
              </div>
            </div>
       
              <div>
              <label class="w-full tw-form-label">Start date</label>
              <div class="my-1">
                <input v-model="editstart_date" name="editstart_date"  value="editstart_date" type="date" class="tw-form-control w-full"><span v-if="errors1.start_date" class="text-red-500 text-xs font-semibold">{{errors1.start_date[0]}}</span>
              </div>
               </div>
                <!-- *** -->
                <!-- *** -->
                    <div >
                     <label class="w-full tw-form-label">End date</label>
                     <div class="my-1">
                      <input v-model="editend_date" name="editend_date"  value="editend_date" type="date" class="tw-form-control w-full"><span v-if="errors1.end_date" class="text-red-500 text-xs font-semibold">{{errors1.end_date[0]}}</span>
                      </div>
                    </div>
                <!-- *** -->
                <div >
                     <label class="w-full tw-form-label">Remarks</label>
                     <div class="my-1">
                      <textarea v-model="editremarks" name="editremarks"  value="editremarks" type="textarea" placeholder="remarks" class="tw-form-control w-full"></textarea><span v-if="errors1.editremarks" class="text-red-500 text-xs font-semibold">{{errors.remarks[0]}}</span>
                      </div>
                    </div>



          
            
            </div>
            <div class="flex items-center">

              <div class="my-2">
                <input type="button" name=""  value="submit" @click="updatelocationproduct(edit.id)" class="btn btn-submit blue-bg text-white rounded px-3 py-1 mr-3 text-sm font-medium cursor-pointer">
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </portal>
<!-- End modal -->
  </div>
</template>

<script>
import { bus } from "../../../app";
import PortalVue from "portal-vue";

export default {
  props:['url'],
  data () {
    return {
      locations:[],
      edit:[],
      location_id:'',
      product_unique_code:'',
     
      start_date:'',
      end_date:'',
      remarks:'',
      editlocation_id:'',
      editproduct_unique_code:'',
      
      editstart_date:'',
      editend_date:'',
      editremarks:'',
      show:0,
      editshow:0,
      errors:[],
      errors1:[],
     
    }
  },

  methods:{
    getlist()
    {
       axios.get('/admin/productcode/activelocation').then(response=>{
                  //console.log(response);
                  this.locations=response.data;
                  
                 });
    },
    addlocationproduct()
    {

      this.errors=[];
                 axios.post("/admin/locationproduct/add",{
                    location_id:this.location_id,
                    product_unique_code:this.product_unique_code,
                    start_date:this.start_date,
                    remarks:this.remarks,
                    //end_date:this.end_date,
                    
                  }).then(response => {
                    //console.log(response);
                    this.success = response.data.message;
                    this.closeModal();
                    this.resetForm();
                    //this.getlist();
                     bus.$emit("addform", response.data.message);
                 }).catch(error => {
                   this.errors = error.response.data.errors;
                 });
    },
    locationproductEdit(id)
    {
      
     this.editshow=1;
      axios.get('/admin/locationproduct/'+id+'/edit').then(response => {
        //console.log(response.data);
            this.edit=response.data;
            console.log(this.edit);
            this.editlocation_id=this.edit.location_id;
            this.editstart_date=this.edit.start_date;
            this.editend_date=this.edit.end_date;
            this.editremarks=this.edit.remarks;
          });   
    },
    updatelocationproduct(id)
    {

      axios.post('/admin/locationproduct/'+id+'/update',{
        
        location_id:this.editlocation_id,
        //product_unique_code:this.product_unique_code,
        start_date:this.editstart_date,
        end_date:this.editend_date,
        remarks:this.editremarks,
      }).then(response => {
        this.success    = response.data.message;
        this.close();
        this.resetedit();
        //this.getlist();
         bus.$emit("addform", response.data.message);
      }).catch(error=>{
        this.errors1=error.response.data.errors;
      });    
    },

   
    resetForm()
               {
                this.location_id='';
                this.product_unique_code='';                
                this.start_date='';
                this.end_date='';
                this.remarks='';
                this.errors=[];
               },
    resetedit()
    {
      this.editlocation_id='';
      this.editproduct_unique_code='';      
      this.editstart_date='';
      this.editend_date='';
      this.editremarks='';
      this.errors1=[];      
    },
    showModal(codeid)
    {
      //alert(codeid);
      this.product_unique_code=codeid;
      this.show = 1;
     // this.reset();
    },
    
    closeModal()
    {
      this.show = 0;
      this.resetForm();
    },
    close()
    {
      this.editshow=0;
      this.resetedit();
    },
    closeMsg()
    {
      this.success=null;
    }

   

  },
  
  created()
  {   
    this.getlist();
    
  }
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
    overflow:auto;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
 /* height: 550px;*/
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.text-danger
{
  color:red;
}
</style>