<template>
    <div class=" overflow-x-scroll lg:overflow-x-auto md:overflow-x-auto py-3" v-bind:class="[this.profile_tab==2?'block' :'hidden']">
        <div class="flex justify-end w-full px-3" v-if="Object.keys(timetables).length > 0"> 
            <div class="flex flex-row lg:flex-col md:flex-col" v-if="this.mode == 'admin'">
                <a :href="url+'/admin/timetable/edit/'+this.id" title="Edit">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.873 477.873" xml:space="preserve" class="w-4 h-4 fill-current text-black-500 mx-2"><g><g><path d="M392.533,238.937c-9.426,0-17.067,7.641-17.067,17.067V426.67c0,9.426-7.641,17.067-17.067,17.067H51.2 c-9.426,0-17.067-7.641-17.067-17.067V85.337c0-9.426,7.641-17.067,17.067-17.067H256c9.426,0,17.067-7.641,17.067-17.067 S265.426,34.137,256,34.137H51.2C22.923,34.137,0,57.06,0,85.337V426.67c0,28.277,22.923,51.2,51.2,51.2h307.2 c28.277,0,51.2-22.923,51.2-51.2V256.003C409.6,246.578,401.959,238.937,392.533,238.937z"></path></g></g> <g><g><path d="M458.742,19.142c-12.254-12.256-28.875-19.14-46.206-19.138c-17.341-0.05-33.979,6.846-46.199,19.149L141.534,243.937 c-1.865,1.879-3.272,4.163-4.113,6.673l-34.133,102.4c-2.979,8.943,1.856,18.607,10.799,21.585 c1.735,0.578,3.552,0.873,5.38,0.875c1.832-0.003,3.653-0.297,5.393-0.87l102.4-34.133c2.515-0.84,4.8-2.254,6.673-4.13 l224.802-224.802C484.25,86.023,484.253,44.657,458.742,19.142z M434.603,87.419L212.736,309.286l-66.287,22.135l22.067-66.202 L390.468,43.353c12.202-12.178,31.967-12.158,44.145,0.044c5.817,5.829,9.095,13.72,9.12,21.955 C443.754,73.631,440.467,81.575,434.603,87.419z"></path></g></g></svg>
                </a>
            </div>   
        </div>
        <div class="flex flex-wrap custom-table mx-3 my-3" v-if="Object.keys(timetables).length > 0">
            <table class="w-full">
                <thead class="">
                    <tr class="">
                        <th class="text-left text-sm px-2 py-2 text-grey-darker"> Day </th>
                        <th class="text-left text-sm px-2 py-2 text-grey-darker" v-for="count in periodCount"> {{ count }} </th>
                    </tr>
                </thead> 
                <tbody>
                    <tr v-for="(timetable,index) in timetables">
                        <td class="py-3 px-2" style="border-style: none;">
                            <p class="font-semibold text-xs">{{ timetable.day }}</p>
                        </td>
                        <td class="py-3 px-2" style="border-style: none;" v-for="(schedule,list) in timetable.schedule">
                            <p class="font-semibold text-xs">{{ schedule.subject_id }}</p>
                            <p class="font-semibold text-xs">({{ schedule.start_time }} - {{ schedule.end_time }})</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="my-5 items-center" v-else>
            <p class="font-semibold text-s" style="text-align: center">No Records Found</p>
        </div>
    </div>
</template>

<script>
    import { bus } from "../../../app";
    export default {
        props:['url','id','mode'],
        data () {
            return {
                profile_tab:'',
                timetables:[],
                periodCount:'',
                errors:[],
                success:null,
            }
        },

        methods:
        {
            getData()
            {
                axios.get('/'+this.mode+'/standardLink/show/timetable/'+this.id).then(response => {
                    this.timetables = response.data.timetable;
                    this.periodCount = response.data.periodCount;
                    //console.log(this.timetables);   
                    //console.log(this.periodCount);   
                });
            },
        },
  
        created()
        {   
            this.getData();
    
            bus.$on("dataProfileTab", data => {
                if(data!='')
                {
                    this.profile_tab=data;                   
                }
            });   
        }
    }
</script>