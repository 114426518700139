<template>
 <!--  <div class="px-3 overflow-x-scroll lg:overflow-x-auto md:overflow-x-auto py-3" v-bind:class="[this.product_tab==1?'block' :'hidden']"> -->
  <div>
     <div v-if="this.success!=null" class="alert alert-success" id="success-alert">{{this.success}}<button  @click="closeMsg()">
              &times;
            </button></div>
  <div class="flex items-center">
        <h1 class="admin-h1 my-3">Product Information</h1>
      </div>
    <div>
    <table class="w-full custom-table overflow-x-auto">
      <thead>
        <tr>
          <th>Code</th>
          <th v-if="showowner">Owner</th>
          <th v-if="showlocation">Location</th>
          <th v-if="showcondition">Condition</th>
          <th >Options</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="code  in products" >
          <td><a :href="'/admin/productcode/'+code.id+'/show'" >{{code.code}}</a></td>
          <td v-if="code.ownership_status==1">
            <span v-if="code.owner.username!=null">{{code.owner.username}}</span>
             <span v-else="">----</span>
          </td>
          <td v-if="code.location_status==1">
            <span v-if="code.location.location!=null">{{code.location.location}}</span>
            <span v-else="">----</span>
          </td>
          <td v-if="code.condition_status==1">
            <span v-if="code.condition.condition!=null">{{code.condition.condition}}</span>
            <span v-else="">----</span>
          </td>
          <td  class=" ">
          <div class="flex">
             <div v-show="show"  class="flex flex-wrap" v-bind:id="'printme'+code.id"  ref="contents">
      
        <p class="mb-0 w-1/6 tw-form-label">{{code.code}}</p><br>
        <qrcode-vue title="Title from vue-headful" :value="url+'/admin/product/'+code.code+'/info'" :size="100" level="H" renderAs="svg"></qrcode-vue>
    </div>
    <div>

  <a title="print QR code" class=" cursor-pointer" @click="print(code.id,code.code)"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="w-4 h-4 fill-current text-black-500 mx-1"

   viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
  <g>
    <g>
      <path d="M456,288h-56V0H96v288H40c-22.056,0-40,17.944-40,40v168h496V328C496,305.944,478.056,288,456,288z M112,16h272v304H112
        V16z M480,480H16V328c0-13.232,10.768-24,24-24h56v16H64v16h368v-16h-32v-16h56c13.232,0,24,10.768,24,24V480z"/>
      <path d="M128,272h240V32H128V272z M304,48h48v48h-48V48z M144,48h48v48h-48V48z M144,112h64V48h80v64h64v144H208v-64h-64V112z
         M144,208h48v48h-48V208z"/>
      <rect x="160" y="128" width="48" height="16"/>
      <rect x="320" y="64" width="16" height="16"/>
      <rect x="160" y="64" width="16" height="16"/>
      <rect x="160" y="224" width="16" height="16"/>
      <rect x="160" y="160" width="16" height="16"/>
      <rect x="192" y="160" width="16" height="16"/>
      <polygon points="272,96 240,96 240,64 224,64 224,112 272,112      "/>
      <rect x="224" y="128" width="16" height="48"/>
      <rect x="224" y="192" width="16" height="48"/>
      <rect x="256" y="128" width="32" height="16"/>
      <polygon points="320,176 336,176 336,128 304,128 304,144 320,144      "/>
      <rect x="256" y="160" width="16" height="48"/>
      <rect x="256" y="224" width="48" height="16"/>
      <rect x="288" y="160" width="16" height="16"/>
      <rect x="288" y="192" width="16" height="16"/>
      <rect x="320" y="192" width="16" height="48"/>
      <path d="M56,416c-13.232,0-24,10.768-24,24s10.768,24,24,24s24-10.768,24-24S69.232,416,56,416z M56,448c-4.408,0-8-3.592-8-8
        c0-4.408,3.592-8,8-8s8,3.592,8,8C64,444.408,60.408,448,56,448z"/>
      <path d="M120,416c-13.232,0-24,10.768-24,24s10.768,24,24,24s24-10.768,24-24S133.232,416,120,416z M120,448
        c-4.408,0-8-3.592-8-8c0-4.408,3.592-8,8-8s8,3.592,8,8C128,444.408,124.408,448,120,448z"/>
      <path d="M168,464h160V352H168V464z M184,368h128v80H184V368z"/>
      <rect x="280" y="416" width="16" height="16"/>
      <rect x="200" y="416" width="64" height="16"/>
    </g>
  </g>
</g>
</svg></a>
  </div>
         <div v-if="code.ownership_status==1">
            <portal-target v-if="code.owner.username==null" name="addownership" :slot-props="{codeid:code.owner.ownershipid,code:code.id}"></portal-target>
            <portal-target v-else="" name="editownership" :slot-props="{codeid:code.owner.ownershipid}"></portal-target>
         </div>
          <div v-if="code.location_status==1">
           <portal-target v-if="code.location.location==null" name="addlocation" :slot-props="{code:code.id}"></portal-target>
            <portal-target v-if="code.location.location!=null" name="editlocation" :slot-props="{codeid:code.location.ownershipid}"></portal-target>
          </div>
          <div v-if="code.condition_status==1">
            <portal-target  name="addcondition" :slot-props="{code:code.id}"></portal-target>
           <!--  <portal-target  name="editconditions" :slot-props="{codeid:code.condition.ownershipid}"></portal-target> -->
          </div>
          </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>

 <portal-target name="ownershipform"></portal-target>
 <portal-target name="editownershipform"></portal-target>
 <portal-target name="addlocationform"></portal-target>
 <portal-target name="editlocationform"></portal-target>
 <portal-target name="addconditionform"></portal-target>
 <portal-target name="editconditionform"></portal-target>
 <ownershipform :url="url" ></ownershipform>      
 <locationform :url="url" ></locationform>
 <conditionform :url="url"></conditionform>
  </div>
</template>

<script>
import { bus } from "../../../app";
import PortalVue from "portal-vue";
import Vue from 'vue'
import QrcodeVue from 'qrcode.vue'
import VueHtmlToPaper from 'vue-html-to-paper';
 import ownershipform from './addproductowner';
 import locationform from './addproductlocation';
 import conditionform from './addproductcondition';
Vue.use(QrcodeVue);
Vue.use(VueHtmlToPaper);
  export default {
      props:['url','productid','ownership_status','track_status','condition_status'],
      data () {
        return {
          products:[],
          users:[],
          location:[],
          errors:[],
          success:null,
          showlocation:true,
          showowner:true,
          showcondition:true,
          show:false,
          success:null,
        /*   config: {
            value: 'https://www.baidu.com',
            imagePath: '/uploads/birthday.png',
            filter: 'color'
        }, 
         downloadButton: true,  */  
        }
      },
      components: {
      QrcodeVue,
      VueHtmlToPaper,
      ownershipform,
      locationform,
      conditionform
     
    },
      methods:
      {
        getData()
          {
            axios.get('/admin/product/'+this.productid+'/detail').then(response => {
            this.products = response.data.data;
           // console.log(this.products) 
            });
          },

       print(id,code)
       {
         document.title='QR_code_'+code;
         this.$htmlToPaper('printme'+id);  
       },
       closeMsg()
       {
         this.success=null;
       },
      },
  
      created()
      {    
        this.getData();
         if(this.condition_status==0)
              {
                this.showcondition=false;
              }
              if(this.track_status==0)
              {
                this.showlocation=false;
              }
              if(this.ownership_status==0)
              {
                this.showowner=false;
              }
            
         bus.$on("addform", data => {
        if(data!='')
        {
          this.success=data; 
          this.getData();                  
        }
      });        
      }
  }
</script>