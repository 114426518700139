<template>
  <div class="relative">
   
   <portal to="addcondition">
    <p slot-scope="{code}" >
       <a  class=" cursor-pointer" title="add-condition" @click="showModal(code)" ><svg  enable-background="new 0 0 24 24"  viewBox="0 0 24 24" class="w-4 h-4 fill-current text-black-500 mx-1" xmlns="http://www.w3.org/2000/svg"><path d="m9.02 21h-6.52c-1.378 0-2.5-1.121-2.5-2.5v-16c0-1.379 1.122-2.5 2.5-2.5h12c1.378 0 2.5 1.121 2.5 2.5v6.06c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6.06c0-.827-.673-1.5-1.5-1.5h-12c-.827 0-1.5.673-1.5 1.5v16c0 .827.673 1.5 1.5 1.5h6.52c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m13.5 9h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m9.5 13h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m8.5 5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5s-.224.5-.5.5z"/><path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.033 0-5.5 2.468-5.5 5.5s2.467 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.467-5.5-5.5-5.5z"/><path d="m17.5 21c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6c0 .276-.224.5-.5.5z"/><path d="m20.5 18h-6c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h6c.276 0 .5.224.5.5s-.224.5-.5.5z"/></svg></a>
     </p>
    </portal>
    <portal  to="editconditions">
    <p slot-scope="{codeid}" >        
         
          <a  class=" cursor-pointer" title="edit-condition" @click="productconditionEdit(codeid)" ><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="w-4 h-4 fill-current text-black-500 mx-1"
   viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
  <g>
    <g>
      <path d="M352.459,220c0-11.046-8.954-20-20-20h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206
        C343.505,240,352.459,231.046,352.459,220z"/>
      <path d="M126.459,280c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20H251.57c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20
        H126.459z"/>
      <path d="M173.459,472H106.57c-22.056,0-40-17.944-40-40V80c0-22.056,17.944-40,40-40h245.889c22.056,0,40,17.944,40,40v123
        c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112,0-80,35.888-80,80v352
        c0,44.112,35.888,80,80,80h66.889c11.046,0,20-8.954,20-20C193.459,480.954,184.505,472,173.459,472z"/>
      <path d="M467.884,289.572c-23.394-23.394-61.458-23.395-84.837-0.016l-109.803,109.56c-2.332,2.327-4.052,5.193-5.01,8.345
        l-23.913,78.725c-2.12,6.98-0.273,14.559,4.821,19.78c3.816,3.911,9,6.034,14.317,6.034c1.779,0,3.575-0.238,5.338-0.727
        l80.725-22.361c3.322-0.92,6.35-2.683,8.79-5.119l109.573-109.367C491.279,351.032,491.279,312.968,467.884,289.572z
         M333.776,451.768l-40.612,11.25l11.885-39.129l74.089-73.925l28.29,28.29L333.776,451.768z M439.615,346.13l-3.875,3.867
        l-28.285-28.285l3.862-3.854c7.798-7.798,20.486-7.798,28.284,0C447.399,325.656,447.399,338.344,439.615,346.13z"/>
      <path d="M332.459,120h-206c-11.046,0-20,8.954-20,20s8.954,20,20,20h206c11.046,0,20-8.954,20-20S343.505,120,332.459,120z"/>
    </g>
  </g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

          </a>
       </p>
    </portal>
  <!-- add condtion -->
  <portal  to="addconditionform">
        <div v-if="this.show == 1" class="modal modal-mask">
      <div class="modal-wrapper px-4">
        <div class="modal-container w-full  max-w-md px-8 mx-auto">
          <div class="modal-header flex justify-between items-center">
            <h2>Add Product Condition</h2>
            <button id="close-button" class="modal-default-button text-2xl py-1" @click="closeModal()">
              &times;
            </button>
          </div>
          

              <div class="modal-body" >
            <div class="flex items-center">
              <div class="w-full"> 
      

              <div>
              <label class="tw-form-label">Conditions</label>
              <div class="my-1">
                      <textarea v-model="condition" name="condition"  value="condition" type="textarea" placeholder="condition" class="tw-form-control w-full"></textarea><span v-if="errors.condition" class="text-red-500 text-xs font-semibold">{{errors.condition[0]}}</span>
              </div>
              <div>
              <label class="tw-form-label">Date</label>
              <div class="my-1">
                      <input v-model="date" name="date"  value="date" type="date" class="tw-form-control w-full"><span v-if="errors.date" class="text-red-500 text-xs font-semibold">{{errors.date[0]}}</span>
              </div>

              </div></div>
              </div></div></div>
            <div class="flex items-center">

              <div class="my-2 ">
                <input type="button" name=""  value="submit" @click="addproductcondition()" class="btn btn-submit blue-bg text-white rounded px-3 py-1 mr-3 text-sm font-medium cursor-pointer">
              </div>
            </div>
          </div>
          </div></div>
        </portal>
    <!-- Edit topic model -->
    <portal  to="editconditionform">
       <div v-if="this.editshow == 1" class="modal modal-mask">
      <div class="modal-wrapper px-4">
        <div class="modal-container w-full  max-w-md px-8 mx-auto">
          <div class="modal-header flex justify-between items-center">
            <h2>Edit Product Condition</h2>
            <button id="close-button" class="modal-default-button text-2xl py-1" @click="close()">
              &times;
            </button>
          </div>
          <div class="modal-body" >
            <div class="">
           
                <div >
                     <label class="w-full tw-form-label">Condition</label>
                     <div class="my-1">
                      <textarea v-model="editcondition" name="editcondition"  value="editcondition" type="textarea" placeholder="condition" class="tw-form-control w-full"></textarea><span v-if="errors1.condition" class="text-red-500 text-xs font-semibold">{{errors1.condition[0]}}</span>
                      </div>
                    </div>

                    <div>
              <label class="w-full tw-form-label">Date</label>
              <div class="my-1">
                <input v-model="editdate" name="editdate"  value="editdate" type="date" class="tw-form-control w-full"><span v-if="errors1.date" class="text-red-500 text-xs font-semibold">{{errors1.date[0]}}</span>
              </div>
               </div>

            </div>
            <div class="flex items-center">

              <div class="my-2">
                <input type="button" name=""  value="submit" @click="updateproductcondition(edit.id)" class="btn btn-submit blue-bg text-white rounded px-3 py-1 mr-3 text-sm font-medium cursor-pointer">
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </portal>
<!-- End modal -->
  </div>
</template>


<script>
import { bus } from "../../../app";
import PortalVue from "portal-vue";

export default {
  props:['url'],
  data () {
    return {
      edit:[],
      product_unique_code:'',
      condition:'',
      date:'',      
      editproduct_unique_code:'',
      editcondition:'',
      editdate:'',
      show:0,
      editshow:0,
      product_tab:'',
      errors:[],
      errors1:[],
      success:null,
      page_count:0,
      page:0,
      total :'',
    }
  },

  methods:{
   
    addproductcondition()
    {

      this.errors=[];
                 axios.post("/admin/productcondition/add",{
                    
                    product_unique_code:this.product_unique_code,
                    condition:this.condition,
                    date:this.date,
                   
                  }).then(response => {
                   // console.log(response);
                    this.success = response.data.message;
                    this.closeModal();
                    this.resetForm();
                     bus.$emit("addform", response.data.message);
                 }).catch(error => {
                   this.errors = error.response.data.errors;
                 });
    },
    productconditionEdit(id)
    {
     this.editshow=1;
      axios.get('/admin/productcondition/'+id+'/edit').then(response => {
            this.edit=response.data;
            this.editproduct_unique_code=this.edit.product_unique_code;
            this.editcondition=this.edit.condition;
            this.editdate=this.edit.date;
           });   
    },
    updateproductcondition(id)
    {
     
      axios.post('/admin/productcondition/'+id+'/update',{
        
        condition:this.editcondition,
        date:this.editdate,
      
      }).then(response => {
        this.success    = response.data.message;
        this.close();
        this.resetedit();
         bus.$emit("addform", response.data.message);
      }).catch(error=>{
        this.errors1=error.response.data.errors;
      });    
    },

   
    resetForm()
               {
                
                this.product_unique_code='';                
                this.condition='';
                this.date='';
                this.errors=[];
               },
    resetedit()
    {
      
      this.editproduct_unique_code='';
      this.editcondition='';            
      this.editdate='';
      
      this.errors1=[];      
    },
    showModal(id)
    {
      this.product_unique_code=id;
      this.show = 1;
     // this.reset();
    },
    
    closeModal()
    {
      this.show = 0;
      this.resetForm();
    },
    close()
    {
      this.editshow=0;
      this.resetedit();
    },
    closeMsg()
    {
      this.success=null;
    }
   

  },
  
  created()
  {   

     
  }
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
    overflow:auto;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
 /* height: 550px;*/
  overflow:auto;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.text-danger
{
  color:red;
}
</style>